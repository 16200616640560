<template>
  <base-page-item>
    <v-card class="pa-6">
      <v-row
        class="spacer mb-6"
        no-gutters
      >
        <v-col cols="3">
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Worker id: </span> {{ localWorker._id }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Device id: </span> {{ localWorker.device_id }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Instance number: </span> {{ localWorker.instance_number }}
          </p>
          <p
            v-if="localWorker.credentials"
            class="text-left mb-0"
          >
            <span class="font-weight-bold mr-1">Credentials id: </span> {{ localWorker.credentials._id }}
            <span class="font-weight-bold mr-1">Credentials email: </span> {{ localWorker.credentials.username }}
          </p>
          <p class="text-left mb-0">
            <span class="font-weight-bold mr-1">Ip address: </span> {{ localWorker.ip_address }}
          </p>
        </v-col>
        <v-col cols="3">
          <p
            v-if="localWorker.status !== 'active'"
            class="text-left mb-0"
          >
            <span class="font-weight-bold red--text mr-1">Inactive!</span>
            <a
              :href="href"
              class="href"
              target="_blank"
            > Open panel </a>
          </p>
          <p
            v-if="hasChallenge"
            class="text-left mb-0"
          >
            <span class="font-weight-bold red--text mr-1">Has challenge!</span>
            <a
              :href="href"
              class="href"
              target="_blank"
            > Open panel </a>
          </p>
        </v-col>
        <v-col cols="3" />
        <v-col cols="1" />
        <v-col cols="2">
          <v-btn
            depressed
            :color="localWorker.status === 'active' ? 'error' : 'gray'"
            class="mb-4"
            @click="updateWorker"
          >
            mark {{ localWorker.status === 'active' ? 'in' : '' }}active
          </v-btn>
        </v-col>
      </v-row>
      <!--      {{ worker }}-->
    </v-card>
  </base-page-item>
</template>

<script>
  import BasePageItem from '@/components/base/BasePageItem';
  import { updateWorker } from '@/api/workersMethods';
  import { getPanelHref } from '@/mixins/getPanelHref';

  export default {
    name: 'WorkersPageItem',
    components: {
      BasePageItem,
    },
    mixins: [getPanelHref],
    props: {
      worker: {
        type: Object,
        default: () => {},
      },
    },
    data () {
      return {
        localWorker: this.worker,
      };
    },
    computed: {
      hasChallenge () {
        return this.localWorker.credentials && this.localWorker.credentials.challenged_at;
      },
      href () {
        return this.getPanelHref(this.localWorker.device_name);
      },
    },
    methods: {
      async updateWorker () {
        const status = this.localWorker.status === 'active' ? 'paused' : 'active';

        this.localWorker = await updateWorker(this.localWorker._id, false, status);
      },
    },
  };
</script>

<style scoped>

</style>
