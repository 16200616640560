export const getPanelHref = {
  methods: {
    getPanelHref (deviceName = '') {
      let href = '';

      if (deviceName.includes('vdsina')) {
        href = 'https://cp.vdsina.ru/vds/view/';
      } else if (deviceName.includes('macloud')) {
        href = 'https://cp.macloud.ru/vds/view/';
      }

      const number = deviceName.split('-')[0].split('v')[1];

      return href + number;
    },
  },
};
